<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Customers</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Customers"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: parentRouteName }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Customers
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="customers"
        class="mt-4"
        no-data-text="No Archived Customers found"
      >
        <template v-slot:item.full_name="{ item }">
          <router-link
            :to="{
              name: 'module-melrose-customers-individual',
              params: { customerId: item.id },
            }"
            >{{ item.full_name }}</router-link
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="yellow lighten-4 yellow--text text--darken-3"
                v-on="on"
                @click="openRestore(item)"
              >
                <v-icon small>mdi-restore</v-icon>
              </v-btn>
            </template>
            <span>Restore</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="restoreDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Restore Customer</v-card-title>
        <v-card-text
          >Are you sure you want to restore
          {{ restoreDialog.customer.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestore"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestore"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: String,
    parentRouteName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [],
      tableHeaders: [
        { text: "Full Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phone" },
        { text: "Position", value: "buyers_position" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      restoreDialog: {
        open: false,
        loading: false,
        customer: {},
      },
    };
  },

  mounted() {
    this.breadcrumbs.push(
      {
        text: this.pageTitle,
        disabled: false,
        exact: true,
        to: {
          name: this.parentRouteName,
        },
      },
      {
        text: "Archived",
        disabled: true,
      }
    );
  },

  computed: {
    customers() {
      let customers = this.$store.getters["melrose/customersStore/archived"];

      if (this.searchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.full_name.toLowerCase();
          const email = c.email.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm) || email.includes(searchTerm);
        });
      }

      return customers;
    },
  },

  methods: {
    openRestore(customer) {
      this.restoreDialog.customer = customer;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.customer = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("melrose/customersStore/restoreCustomer", {
          appId,
          customerId: this.restoreDialog.customer.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
